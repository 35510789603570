import axios from 'axios';
import { PcBuildType } from 'domain/equipment/build-type';

type CheckoutBodyItem = {
	itemId: string;
	qty: number;
	listed: boolean;
};

export type CheckoutBodyProps = {
	catalogId: string;
	pricelistId: string;
	items: CheckoutBodyItem[];
	affiliateId?: string;
};

export const getCheckout = async (body: CheckoutBodyProps) => {
	const response = await axios.post(
		process.env.GATSBY_PC_BUILDER_CHECKOUT_ENDPOINT || '',
		body,
	);
	return response;
};
