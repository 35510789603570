import axios from 'axios';
import { CheckoutBodyProps } from './get-checkout';

export const getOrderNumber = async (body: CheckoutBodyProps) => {
	const response = await axios.post(
		process.env.GATSBY_PC_BUILDER_ORDER_ENDPOINT || '',
		body,
	);
	return response.data?.id;
};
