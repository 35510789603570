import React, {
	createContext,
	useReducer,
	useEffect,
	useMemo,
	useContext,
	useCallback,
	useState,
} from 'react';
import {
	CartDTO,
	CartAction,
	CartItemDTO,
	MetaDataKeys,
	MetaData,
} from 'domain/equipment/cart';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { isCartExpired } from 'domain/equipment/is-cart-expired';
import { uid } from 'utils/uid';
import { reportAnEvent } from 'infrastructure/ga4/ga4';
import { useAffiliateId } from '../use-affiliate-id';
import { CheckoutBodyProps, getCheckout } from './get-checkout';
import { getOrderNumber as getOrderNumberRequest } from './get-order-number';

const cartReducer: React.Reducer<
	CartDTO<IGatsbyImageData>,
	CartAction<IGatsbyImageData>
> = (state, action) => {
	switch (action.type) {
		case 'ADD_TO_CART':
			return {
				...state,
				id: state.id || uid(),
				updateTimestamp: new Date(),
				items: action.replace
					? action.items
					: [...(state.items || []), ...action.items],
				productUrl: action.productUrl ?? state.productUrl,
			};
		case 'REMOVE_FROM_CART': {
			return {
				...state,
				id: state.id || uid(),
				updateTimestamp: new Date(),
				items: [...state.items.filter((item) => item.id !== action.id)],
			};
		}
		case 'LOAD_CART': {
			return action.localCart;
		}
		case 'RESET_CART': {
			return {} as CartDTO<IGatsbyImageData>;
		}
		default:
			return state;
	}
};

interface CartContextType {
	cart: CartDTO<IGatsbyImageData>;
	dispatch: React.Dispatch<CartAction<IGatsbyImageData>>;
	isLoaded: boolean;
}

export const CartContext = createContext<CartContextType>(
	{} as CartContextType,
);

interface CartProviderProps {
	children: React.ReactNode;
}

export const CartProvider = ({ children }: CartProviderProps) => {
	const [cart, dispatch] = useReducer(
		cartReducer,
		{} as CartDTO<IGatsbyImageData>,
	);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const localCart = JSON.parse(
			localStorage.getItem('localCart') || '{}',
		) as CartDTO<IGatsbyImageData>;
		const isValidCart = isCartExpired(
			new Date(),
			new Date(localCart.updateTimestamp),
		);
		if (localCart && !isValidCart) dispatch({ type: 'LOAD_CART', localCart });
		setIsLoaded(true);
	}, []);

	useEffect(() => {
		if (isLoaded) {
			localStorage.setItem('localCart', JSON.stringify(cart));
		}
	}, [cart]);

	const contextValue = useMemo(
		() => ({
			cart,
			dispatch,
			isLoaded,
		}),
		[cart],
	);

	return (
		<CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
	);
};

export type GroupedCartItems = {
	[key in string | number]: (CartItemDTO<IGatsbyImageData> & {
		cartItemId: string;
	})[];
};

export const useCart = () => {
	const { dispatch, cart, isLoaded } = useContext(CartContext);
	const affiliateId = useAffiliateId();

	const [isFetching, setIsFetching] = useState(false);

	const cartValue = useMemo(
		() =>
			cart.items?.reduce((prev, curr) => prev + curr.price * curr.quantity, 0),
		[cart],
	);

	const addToCart = useCallback(
		(
			items: CartItemDTO<IGatsbyImageData>[],
			replace = false,
			mainProductUrl?: string,
		) => {
			if (replace && cart?.items?.length) {
				reportAnEvent('remove_from_cart', cart.items);
			}

			reportAnEvent('add_to_cart', items);

			dispatch({
				type: 'ADD_TO_CART',
				items,
				replace,
				productUrl: mainProductUrl,
			});
		},
		[cart],
	);

	const removeFromCart = (items: any) => {
		// TODO
		reportAnEvent('remove_from_cart', items);

		const ids = items.map((item: any) => item.id); // TODO

		ids.forEach((id: any) =>
			dispatch({
				// TODO
				type: 'REMOVE_FROM_CART',
				id,
			}),
		);
	};

	const cartItemsCount = useMemo(() => cart.items?.length, [cart]);

	const buildCartApiBody = () => {
		const { items } = cart;
		const components = items?.filter((item) => !!item?.componentId) ?? [];
		const services = items?.filter((item) => !!item?.serviceId) ?? [];

		const catalogId = components[0].groupTags.build_catalog_id!;
		const pricelistId = components[0].groupTags.build_pricelist_id!;

		const body: CheckoutBodyProps = {
			catalogId,
			pricelistId,
			items: [
				...components.map((item) => ({
					itemId: item.componentId!,
					qty: item.quantity,
					listed: false,
				})),
				...services.map((item) => ({
					itemId: item.serviceId!,
					qty: item.quantity,
					listed: true,
				})),
			],
			affiliateId: affiliateId ?? undefined,
		};

		return body;
	};

	const checkout = async () => {
		setIsFetching(() => true);

		const body = buildCartApiBody();
		const resp = await getCheckout(body);

		setIsFetching(() => false);
		// TODO err handling

		reportAnEvent('begin_to_checkout', cart.items);

		window.location.href = resp.data.url;
	};

	const getOrderNumber = () => {
		const body = buildCartApiBody();
		return getOrderNumberRequest(body);
	};

	const resetCart = (transactionId: string) => {
		reportAnEvent('purchase', cart.items, transactionId);

		dispatch({ type: 'RESET_CART' });
	};

	const getFilteredBy = useCallback(
		({
			include,
			exclude,
		}: {
			include?: Partial<MetaData<IGatsbyImageData>>[];
			exclude?: Partial<MetaData<IGatsbyImageData>>[];
		}) =>
			cart.items?.filter((item) => {
				const includeResult =
					!include ||
					(include &&
						include?.some((condition) =>
							Object.entries(condition).every(
								([key, value]) =>
									item.groupTags &&
									value === item.groupTags[key as MetaDataKeys],
							),
						));

				const excludeResult =
					!exclude ||
					(exclude &&
						exclude?.some((condition) =>
							Object.entries(condition).every(
								([key, value]) =>
									item.groupTags &&
									value !== item.groupTags[key as MetaDataKeys],
							),
						));
				return includeResult && excludeResult;
			}) || [],
		[cart],
	);

	const getUniqueTagValues = useCallback(
		<T extends MetaDataKeys>(tagKey: T) =>
			cart.items
				?.map((item) => item.groupTags[tagKey])
				.filter((item, i, obj) => obj.indexOf(item) === i) || [],
		[cart],
	);

	return {
		cart,
		addToCart,
		getFilteredBy,
		getUniqueTagValues,
		removeFromCart,
		cartValue,
		cartItemsCount,
		checkout,
		resetCart,
		isLoaded,
		isFetching,
		getOrderNumber,
	};
};
