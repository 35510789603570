import { useEffect, useState } from 'react';

const AFFILIATE_ID_KEY = 'affiliateId';
const AFFILIATE_ID_EXPIRY_KEY = 'affiliateIdExpiry';
const EXPIRY_DAYS = 14;

export function useAffiliateId() {
	const [affiliateId, setAffiliateId] = useState<string | null>(null);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const newAffiliateId = urlParams.get('aid');

		const now = new Date();
		const expirationDate = new Date(
			now.getTime() + EXPIRY_DAYS * 24 * 60 * 60 * 1000,
		);

		const storedId = localStorage.getItem(AFFILIATE_ID_KEY);
		const storedExpiry = localStorage.getItem(AFFILIATE_ID_EXPIRY_KEY);

		if (!storedId || !storedExpiry) {
			localStorage.removeItem(AFFILIATE_ID_KEY);
			localStorage.removeItem(AFFILIATE_ID_EXPIRY_KEY);
			setAffiliateId(null);
		} else {
			const expiryDate = new Date(storedExpiry);

			if (expiryDate > now) {
				setAffiliateId(storedId);
			} else {
				localStorage.removeItem(AFFILIATE_ID_KEY);
				localStorage.removeItem(AFFILIATE_ID_EXPIRY_KEY);
				setAffiliateId(null);
			}
		}

		// Update or store new ID if `aid` is in the URL
		if (newAffiliateId) {
			localStorage.setItem(AFFILIATE_ID_KEY, newAffiliateId);
			localStorage.setItem(
				AFFILIATE_ID_EXPIRY_KEY,
				expirationDate.toISOString(),
			);
			setAffiliateId(newAffiliateId);
		}
	}, []);

	return affiliateId;
}
